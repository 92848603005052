<template>
    <div class="page dyygHome">
        <el-row v-if="loading" v-loading="loading" element-loading-text="加载中" class="loadingBox"></el-row>
        <div class="srhBox flex_c_c">
            <el-select v-model="tabType" placeholder="请选择" class="srhSel">
                <el-option label="资源" value="0"></el-option>
                <el-option label="文件" value="1"></el-option>
            </el-select>
            <el-input class="srhIpt homeSrhIpt" v-model.trim="srhStr" clearable maxlength="100"
                      placeholder="请输入名称、关键词等" @keyup.enter.native="search()"></el-input>
            <button class="srhBtn mainBdColor mainBgColor" @click="search"><i class="el-icon-search"></i>搜索</button>
        </div>
        <div class="classifyBox" v-for="(item1, index1) in columnList" :key="index1"
             v-if="dataList[item1.resourceType].length > 0">
            <div class="flex_l_t">
                <div :class="['classifyItem', `classifyItem${index1}`]">
                    <div class="title">「{{item1.columnName}}」</div>
                    <div class="piece">{{numList[item1.resourceType]}}/件</div>
                    <div class="piece">{{((fileSizeList[item1.resourceType])/1024/1024).toFixed(2)}}/M</div>
                    <div class="moreBtn pointer" @click="findOutMore(item1.resourceType)">查看更多</div>
                </div>
                <div class="flex1 classifyDataBox">
                    <div class="item pointer" v-for="(item, index) in dataList[item1.resourceType]" :key="index"
                         @click="details(item)">
                        <div class="itemImgBox">
                            <div :class="['tag', `bg${index1}`]">{{item1.columnName}}</div>
                            <div class="imgNum iconfont icon-tupian">{{item.image_num}}</div>
                            <el-image fit="contain" :src="item.thumb_path" class="itemImg">
                                <el-image slot="placeholder" fit="cover" class="itemImg"
                                          :src="getPlaceholderImg(item1.resourceType)"></el-image>
                                <el-image slot="error" fit="cover" class="itemImg"
                                          :src="getPlaceholderImg(item1.resourceType)"></el-image>
                            </el-image>
                        </div>
                        <div class="info">
                            <el-tooltip effect="dark"
                                        :content="item.div_type=='10'?item.collection_name:item.resource_name"
                                        placement="top-start">
                                <div class="name omit">{{item.div_type=='10'?item.collection_name:item.resource_name}}
                                </div>
                            </el-tooltip>
                            <div class="flex_l_t explainBox" v-if="item.resource_keys">
                                <el-tooltip effect="dark" :content="item.resource_keys[0]" placement="top-start"
                                            :disabled="!item.resource_keys[0]">
                                    <span class="explain0 omit"
                                          v-show="item.resource_keys[0]">{{item.resource_keys[0]}}</span>
                                </el-tooltip>
                                <el-tooltip effect="dark" :content="item.resource_keys[1]" placement="top-start"
                                            :disabled="!item.resource_keys[1]">
                                    <span class="explain1 omit"
                                          v-show="item.resource_keys[1]">{{item.resource_keys[1]}}</span>
                                </el-tooltip>
                                <el-tooltip effect="dark" :content="item.resource_keys[2]" placement="top-start"
                                            :disabled="!item.resource_keys[2]">
                                    <span class="explain2 omit"
                                          v-show="item.resource_keys[2]">{{item.resource_keys[2]}}</span>
                                </el-tooltip>
                            </div>
                            <div class="numberBox">
                                <el-tooltip effect="dark" :content="item.collection_num" placement="top-start"
                                            :disabled="!item.collection_num">
                                    <div class="numberItemBox">
                                        <span class="numberTit" v-show="item.collection_num">编号：</span>
                                        <span v-show="item.collection_num">{{item.collection_num}}</span>
                                    </div>
                                </el-tooltip>
                                <div class="itemBox">
                                        <span class="iconfont icon-ai235"
                                              v-if="hasPermission('myBorrowing:list:borrowing')"
                                              :style="{color: item.isBorrow && item.borrowing.applyStatus == 2 ? '#BC2124' : ''}"
                                              @click.stop="selBook(item)"></span>
                                    <span class="iconfont icon-shoucang-shoucang"
                                          v-show="hasPermission('home:collection')"
                                          :style="{color: item.isCollect ? '#BC2124' : ''}"
                                          @click.stop="collect(item)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--借阅-->
        <BorrowForm ref="borrowForm" @refreshDataList="getList"></BorrowForm>
    </div>
</template>

<script>
    import BorrowForm from './home/BorrowForm' // 借阅弹窗
    export default {
        components: {BorrowForm},
        data() {
            return {
                loading: true,
                fileSizeList:[],//大小list
                numList: [], // 数量list
                dataList: [], // 数据list
                columnList: [],//列表list
                srhStr: '',//搜索项
                tabType: '0',
                onCollection: false,//收藏防重复提交
            }
        },
        mounted() {
            this.getColumn()
            this.getList()
        },
        methods: {
            // 栏目查询
            getColumn() {
                this.$axios(this.api.dyyg.homeColumn, {
                    'size': 100,
                    'current': 0,
                }, "get").then((res) => {
                    if (res.status) {
                        this.columnList = res.data.records
                    }
                })
            },
            // 数据查询
            getList() {
                this.loading = true
                this.$axios(this.api.dyyg.adminIndex, {}, 'get').then(data => {
                    if (data.status) {
                        const _this = this;
                        _this.dataList = data.data[0]
                        _this.numList = data.data[1]
                        _this.fileSizeList = data.data[2]
                        this.loading = false
                    }
                })
            },
            // 搜索
            search() {
                sessionStorage.setItem('homeSrh', this.srhStr)
                sessionStorage.setItem('homeTabType', this.tabType)
                sessionStorage.setItem('resourceType', null)
                this.$router.push({path: '/dyyg/home/retrievedResult'});
            },
            // 查看更多
            findOutMore(val) {
                sessionStorage.setItem('resourceType', val)
                sessionStorage.setItem('homeSrh', this.srhStr)
                this.$router.push({path: '/dyyg/home/retrievedResult'});
            },
            // 查看详情
            details(item) {
                sessionStorage.setItem('dizhi', '/dyyg/home')
                this.$router.push({
                    path: '/dyyg/home/retrievedDetails',
                    query: item,
                });
            },
            // 借阅
            selBook(data) {
                if (data.borrowing && data.borrowing.applyStatus == 0) {
                    this.$message.warning("该数字资源正在走借阅流程，请在我的借阅中查看！")
                    return
                }
                if (data.borrowing == null || data.borrowing.applyStatus == 1) {
                    let root = JSON.parse(JSON.stringify(data))
                    this.$set(root, "foreign_key_id", root.id)
                    this.$set(root, "divType", root.div_type != null ? root.div_type : root.resource_type)
                    root.id = null
                    this.$refs.borrowForm.init([root])
                }
                if(data.borrowing && data.borrowing.applyStatus == 2) {
                    let root = [{
                        id: data.borrowing.id,
                        onExpire: 1,
                    }]
                    const h = this.$createElement;
                    this.$msgbox({
                        title: '提示',
                        message: h('p', null, [
                            h('p', {style: 'color: #000000; margin-bottom: 5px'}, "确认归还此资源吗？"),
                            h('p', {style: 'color: #999999; font-size: 12px'}, '归还后将无法正常查看对应的资源')
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(() => {
                        this.$axios(this.api.dyyg.giveBack, root, 'post').then(data => {
                            if (data && data.status) {
                                this.$message.success('归还成功')
                            } else {
                                this.$message.error('归还失败')
                            }
                            this.getList()
                        })
                    });
                }
            },
            // 收藏
            collect(row) {
                let root = [{
                    id: row.homeCollectId,
                    foreignKeyId: row.id,
                    foreignType: row.div_type != null ? row.div_type : row.resource_type,
                    delFlag: row.homeCollectId != null ? 1 : 0
                }]
                this.$axios(this.api.dyyg.collectionUpdateById, root, 'post').then(data => {
                    if (data.status) {
                        if(root[0].delFlag == 0) {
                            this.$message.success("收藏成功")
                        } else {
                            this.$message.success("取消收藏成功")
                        }
                    } else {
                        if(root[0].delFlag == 0) {
                            this.$message.error("收藏失败")
                        } else {
                            this.$message.error("取消收藏失败")
                        }
                    }
                    this.getList()
                })
            },
            // 获取占位图
            getPlaceholderImg(resourceType) {
                switch (resourceType) {
                    case 0:
                        return require('@/assets/img/collThree.png')
                    case 1:
                        return require('@/assets/img/collPhoto.png')
                    case 2:
                        return require('@/assets/img/collAudio.png')
                    case 3:
                        return require('@/assets/img/collVideo.png')
                    default:
                        return require('@/assets/img/collFile.png')
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    //首页
    .dyygHome {
        font-family: Source Han Sans CN;
        margin: -10px;
        overflow-y: auto;
        height: calc(100% + 20px) !important;
        background-image: url('../../../assets/img/retrievalBg.png');
        background-position: top center;
        background-size: cover;

        .loadingBox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
        }

        .bg0, .bg6 {
            background: #BC2124;
        }

        .bg1, .bg7 {
            background: #45CA7B;
        }

        .bg2, .bg8 {
            background: #00A0E9;
        }

        .bg3, .bg9 {
            background: #712EDA;
        }

        .bg4, .bg10 {
            background: #007CF7;
        }

        .bg5, .bg11 {
            background: #F9AB30;
        }

        .srhBox {
            padding: 3.75rem 0 2.88rem;
        }


        ::placeholder {
            color: #BFBFBF;
        }

        .classifyBox {
            padding: 0 1.25rem;
            margin-bottom: 1.88rem;
        }

        .classifyItem {
            background-size: 100% 100%;
            color: #ffffff;
            text-align: center;
            padding: 0 1.75rem;
            height: 30rem;

            .title {
                width: 12.75rem;
                font-size: 2rem;
                margin: 8.7rem 0 0.38rem;
                white-space: nowrap;
            }

            .piece {
                font-size: 1.4rem;
                opacity: 0.5;
            }

            .moreBtn {
                width: 8.75rem;
                height: 2.5rem;
                line-height: 2.5rem;
                font-size: 1rem;
                margin: 5.63rem auto;
                border-radius: 0rem 1.14rem 0rem 1.14rem;
                border: 1px solid #FFFFFF;
            }
        }

        .classifyItem0, .classifyItem6 {
            background: url("../../../assets/img/classifyBg1.png");
            background-size: 100% 100%;
        }

        .classifyItem1, .classifyItem7 {
            background: url('../../../assets/img/classifyBg2.png');
            background-size: 100% 100%;
        }

        .classifyItem2, .classifyItem8 {
            background: url('../../../assets/img/classifyBg3.png');
            background-size: 100% 100%;
        }

        .classifyItem3, .classifyItem9 {
            background: url('../../../assets/img/classifyBg4.png');
            background-size: 100% 100%;
        }

        .classifyItem4, .classifyItem10 {
            background: url('../../../assets/img/classifyBg5.png');
            background-size: 100% 100%;
        }

        .classifyItem5, .classifyItem11 {
            background: url('../../../assets/img/classifyBg6.png');
            background-size: 100% 100%;
        }

        .classifyDataBox {
            background: #ffffff;
            padding: 0 0.5%;
            height: 30rem;

            .item {
                display: inline-block;
                width: 19%;
                margin: 1.7rem 0.5%;
                border-radius: 0.5rem;
                box-shadow: 0rem 0rem 1rem 0rem rgba(215, 205, 205, 0.75);

                .tag {
                    position: absolute;
                    left: 0;
                    top: 1.25rem;
                    font-size: 1rem;
                    padding: 0.25rem 1rem;
                    color: #ffffff;
                    border-radius: 0rem 1rem 1rem 0rem;
                    z-index: 1;
                }

                .imgNum {
                    position: absolute;
                    right: 0.69rem;
                    bottom: 8%;
                    z-index: 1;
                    padding: 0.05rem 0.88rem;
                    color: #ffffff;
                    background: rgba(0, 0, 0, 0.15);
                    border-radius: 1rem;
                    font-size: 1rem;
                    border: 1px solid #FFFFFF;
                }

                .icon-tupian:before {
                    margin-right: 0.38rem;
                }

                .itemImgBox {
                    position: relative;
                }

                .itemImg {
                    height: 15rem;
                    width: 100%;
                    border-radius: 0.5rem;
                }

                .info {
                    padding: 1rem 1rem;
                    font-size: 1.25rem;
                    color: #000000;
                }

                .explainBox {
                    height: 2.7rem;
                }

                .explain0, .explain1, .explain2 {
                    padding: 0.25rem 0.75rem;
                    background: rgba(168, 33, 38, 0.12);
                    color: #A82126;
                    font-size: 0.88rem;
                    border-radius: 0.25rem;
                    margin-top: 0.75rem;
                    display: inline-block;
                    //width: 31.333%;
                    margin-right: 2%;
                    text-align: center;
                }

                .explain1 {
                    background: rgba(29, 94, 192, 0.12);
                    color: #1D5EC0;
                }

                .explain2 {
                    background: rgba(33, 168, 116, 0.12);
                    color: #21A874;
                }

                .numberBox {
                    margin-top: 0.8rem;
                    width: 100%;

                    .numberItemBox {
                        width: 60%;
                        display: inline-block;
                    }

                    .itemBox {
                        width: 40%;
                        display: inline-block;
                    }

                    .iconfont {
                        color: #A6A6A6;
                        font-size: 1.8rem;
                        vertical-align: middle;
                    }

                    .icon-ai235 {
                        font-size: 2.4rem;
                        margin-right: 1rem;
                    }
                }

                .numberTit {
                    color: #808080;
                }
            }
        }

        .plateBox {
            background: #FFFFFF;
            border-radius: 1.5rem;
            padding: 2.13rem 2.5rem 2.69rem;;
            color: #000000;
            margin: 0 2.44rem 2.44rem;
        }

        .plateTit {
            font-size: 1.75rem;
            margin-bottom: 2rem;

            .plateTitIcon {
                display: inline-block;
                width: 0.31rem;
                height: 1.75rem;
                background: #A82126;
                border-radius: 0.16rem;
                margin-right: 0.5rem;
            }
        }

        .queryItem {
            padding-bottom: 2.6rem;
        }

        .tagTit {
            font-size: 1.25rem;
            width: 6.4rem;
        }

        .unlimited {
            font-size: 1.13rem;
            margin-right: 2rem;
            padding: 0.38rem 1.1rem;
            border-radius: 0.25rem;
        }

        .clearAllBtn {
            padding: 0.3rem 0.94rem;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            border-radius: 0.29rem;
            font-size: 1.13rem;
            text-align: center;
            color: #808080;
        }

        .noData {
            color: #909399;
            text-align: center;
            font-size: 1rem;
            line-height: 123px;
        }

        .resourcesIcon {
            width: 2rem;
            margin: 0 0.5rem;
        }
    }

</style>
